// Event 1 - Connect Wallet
export const gtmEvent_connect_wallet = () => {
  return {
    event: 'connect_wallet',
    location: 'Login page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
  };
};

// Event 2 - Facebook
export const gtmEvent_continue_facebook = () => {
  return {
    event: 'continue_facebook',
    location: 'Login page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
  };
};

// Event 3 - Google
export const gtmEvent_continue_google = () => {
  return {
    event: 'continue_google',
    location: 'Login page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
  };
};

// Event 4 - Apple
export const gtmEvent_continue_apple = () => {
  return {
    event: 'continue_apple',
    location: 'Login page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
  };
};

// Event 5 - Login
export const gtmEvent_login = () => {
  return {
    event: 'login',
    location: 'Login page top pane',
    description: 'Login',
    category: 'Login',
    action: 'Login',
  };
};

// Event 6 - Home
export const gtmEvent_go_to_home = () => {
  return {
    event: 'go_to_home',
    location: 'Login page top pane',
    description: 'HOME',
    category: 'Login',
    action: 'Go to Home',
  };
};

// Event 7 - Deposit
export const gtmEvent_go_to_deposit = () => {
  return {
    event: 'go_to_deposit',
    location: 'Login page top pane',
    description: 'DEPOSIT',
    category: 'Login',
    action: 'Go to Deposit',
  };
};

// Event 8 - Withdrawal
export const gtmEvent_go_to_withdrawal = () => {
  return {
    event: 'go_to_withdrawal',
    location: 'Login page top pane',
    description: 'WITHDRAWAL',
    category: 'Login',
    action: 'Go to Withdrawal',
  };
};

// Event 9 - Help
export const gtmEvent_go_to_help = () => {
  return {
    event: 'go_to_help',
    location: 'Login page top pane',
    description: 'HELP',
    category: 'Login',
    action: 'Go to Help',
  };
};

// Event 10 - Notifications
export const gtmEvent_go_to_notifications = () => {
  return {
    event: 'go_to_notifications',
    location: 'Login page top pane',
    description: 'NOTIFICATIONS',
    category: 'Login',
    action: 'Go to Notifications',
  };
};

// Event 11 - Games
export const gtmEvent_go_to_games = () => {
  return {
    event: 'go_to_games',
    location: 'Login page bottom pane',
    description: 'Games',
    category: 'Login',
    action: 'Go to Games',
  };
};

// Event 12 - How it works
export const gtmEvent_how_it_works = () => {
  return {
    event: 'how_it_works',
    location: 'Login page bottom pane',
    description: 'How it works?',
    category: 'Login',
    action: 'How it works',
  };
};

// Event 13 - Deposit (Bottom Pane)
export const gtmEvent_go_to_deposit_bottom = () => {
  return {
    event: 'go_to_deposit_bottom',
    location: 'Login page bottom pane',
    description: 'Deposit',
    category: 'Login',
    action: 'Go to Deposit',
  };
};

// Event 14 - Withdrawal (Bottom Pane)
export const gtmEvent_go_to_withdrawal_bottom = () => {
  return {
    event: 'go_to_withdrawal_bottom',
    location: 'Login page bottom pane',
    description: 'Withdrawal',
    category: 'Login',
    action: 'Go to Withdrawal',
  };
};

// Event 15 - Contact
export const gtmEvent_go_to_contact = () => {
  return {
    event: 'go_to_contact',
    location: 'Login page bottom pane',
    description: 'Contact',
    category: 'Login',
    action: 'Go to Contact',
  };
};

// Event 16 - Privacy Policy
export const gtmEvent_go_to_privacy_policy = () => {
  return {
    event: 'go_to_privacy_policy',
    location: 'Login page bottom pane',
    description: 'Privacy Policy',
    category: 'Login',
    action: 'Go to Privacy Policy',
  };
};

// Event 17 - Terms of Service
export const gtmEvent_go_to_terms_of_service = () => {
  return {
    event: 'go_to_terms_of_service',
    location: 'Login page bottom pane',
    description: 'Terms of Service',
    category: 'Login',
    action: 'Go to Terms of Service',
  };
};

// Event 18 - Terms of Service (Footnote)
export const gtmEvent_go_to_terms_of_service_footnote = () => {
  return {
    event: 'go_to_terms_of_service_footnote',
    location: 'Login page footnote',
    description: 'Terms of Service',
    category: 'Login',
    action: 'Go to Terms of Service',
  };
};

// Event 19 - Privacy Policy (Footnote)
export const gtmEvent_go_to_privacy_policy_footnote = () => {
  return {
    event: 'go_to_privacy_policy_footnote',
    location: 'Login page footnote',
    description: 'Privacy Policy',
    category: 'Login',
    action: 'Go to Privacy Policy',
  };
};

// Event 20 - OK - Successful Connection
export const gtmEvent_ok_successful_connection = () => {
  return {
    event: 'ok_successful_connection',
    location: 'Login modal',
    description: 'Connection Successful',
    category: 'Login',
    action: 'OK - Successful Connection',
  };
};

// Event 21 - OK - Unsuccessful Connection
export const gtmEvent_ok_unsuccessful_connection = () => {
  return {
    event: 'ok_unsuccessful_connection',
    location: 'Login modal',
    description: 'Connection Unsuccessful',
    category: 'Login',
    action: 'OK - Unsuccessful Connection',
  };
};

// Event 22 - Try Again - Unsuccessful Connection
export const gtmEvent_try_again_unsuccessful_connection = () => {
  return {
    event: 'try_again_unsuccessful_connection',
    location: 'Login modal',
    description: 'Connection Unsuccessful',
    category: 'Login',
    action: 'Try Again - Unsuccessful Connection',
  };
};

// Event 23 - Yes - Sign Out
export const gtmEvent_yes_sign_out = () => {
  return {
    event: 'yes_sign_out',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Connection Unsuccessful',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Yes - Sign Out',
  };
};

// Event 24 - Cancel - Sign Out
export const gtmEvent_cancel_sign_out = () => {
  return {
    event: 'cancel_sign_out',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Connection Unsuccessful',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Sign Out',
  };
};

// Event 25 - Yes - Disconnect Wallet
export const gtmEvent_yes_disconnect_wallet = () => {
  return {
    event: 'yes_disconnect_wallet',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Disconnect Wallet',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Yes - Disconnect Wallet',
  };
};

// Event 26 - Cancel - Disconnect Wallet
export const gtmEvent_cancel_disconnect_wallet = () => {
  return {
    event: 'cancel_disconnect_wallet',
    location: 'Sign Out / Disconnect Wallet',
    description: 'Disconnect Wallet',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Disconnect Wallet',
  };
};

// Event 27 - Play Now
export const gtmEvent_play_now = () => {
  return {
    event: 'play_now',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now',
  };
};

// Event 28 - Download APK
export const gtmEvent_download_apk = () => {
  return {
    event: 'download_apk',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK',
  };
};

// Event 29 - Connect Wallet - Deposit
export const gtmEvent_connect_wallet_deposit = () => {
  return {
    event: 'connect_wallet_deposit',
    location: 'Deposit page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
    label: 'Deposit',
  };
};

// Event 30 - Facebook - Deposit
export const gtmEvent_continue_facebook_deposit = () => {
  return {
    event: 'continue_facebook_deposit',
    location: 'Deposit page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
    label: 'Deposit',
  };
};

// Event 31 - Google - Deposit
export const gtmEvent_continue_google_deposit = () => {
  return {
    event: 'continue_google_deposit',
    location: 'Deposit page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
    label: 'Deposit',
  };
};

// Event 32 - Apple - Deposit
export const gtmEvent_continue_apple_deposit = () => {
  return {
    event: 'continue_apple_deposit',
    location: 'Deposit page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
    label: 'Deposit',
  };
};

// Event 33 - Connect Wallet - Withdraw
export const gtmEvent_connect_wallet_withdraw = () => {
  return {
    event: 'connect_wallet_withdraw',
    location: 'Withdraw page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
    label: 'Withdraw',
  };
};

// Event 34 - Facebook - Withdraw
export const gtmEvent_continue_facebook_withdraw = () => {
  return {
    event: 'continue_facebook_withdraw',
    location: 'Withdraw page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
    label: 'Withdraw',
  };
};

// Event 35 - Google - Withdraw
export const gtmEvent_continue_google_withdraw = () => {
  return {
    event: 'continue_google_withdraw',
    location: 'Withdraw page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
    label: 'Withdraw',
  };
};

// Event 36 - Apple - Withdraw
export const gtmEvent_continue_apple_withdraw = () => {
  return {
    event: 'continue_apple_withdraw',
    location: 'Withdraw page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
    label: 'Withdraw',
  };
};

// Event 37 - Connect Wallet - Notifications
export const gtmEvent_connect_wallet_notifications = () => {
  return {
    event: 'connect_wallet_notifications',
    location: 'Notifications page',
    description: 'Connect Wallet',
    category: 'Login',
    action: 'Connect Wallet',
    label: 'Notifications',
  };
};

// Event 38 - Facebook - Notifications
export const gtmEvent_continue_facebook_notifications = () => {
  return {
    event: 'continue_facebook_notifications',
    location: 'Notifications page',
    description: 'Continue with Facebook',
    category: 'Login',
    action: 'Connect Facebook',
    label: 'Notifications',
  };
};

// Event 39 - Google - Notifications
export const gtmEvent_continue_google_notifications = () => {
  return {
    event: 'continue_google_notifications',
    location: 'Notifications page',
    description: 'Continue with Google',
    category: 'Login',
    action: 'Connect Google',
    label: 'Notifications',
  };
};

// Event 40 - Apple - Notifications
export const gtmEvent_continue_apple_notifications = () => {
  return {
    event: 'continue_apple_notifications',
    location: 'Notifications page',
    description: 'Continue with Apple',
    category: 'Login',
    action: 'Connect Apple',
    label: 'Notifications',
  };
};

// Event 41 - Deposit Package 1
export const gtmEvent_deposit_package_1 = (currency, value) => {
  return {
    event: 'deposit_package_1',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Package 1',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 42 - Deposit Package 2
export const gtmEvent_deposit_package_2 = (currency, value) => {
  return {
    event: 'deposit_package_2',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Package 2',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 43 - Deposit Package 3
export const gtmEvent_deposit_package_3 = (currency, value) => {
  return {
    event: 'deposit_package_3',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Package 3',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 44 - Deposit Package 4
export const gtmEvent_deposit_package_4 = (currency, value) => {
  return {
    event: 'deposit_package_4',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Package 4',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 45 - Deposit Package 5
export const gtmEvent_deposit_package_5 = (currency, value) => {
  return {
    event: 'deposit_package_5',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Package 5',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 46 - Deposit Package 6
export const gtmEvent_deposit_package_6 = (currency, value) => {
  return {
    event: 'deposit_package_6',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Package 6',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 47 - Confirm Deposit
export const gtmEvent_confirm_deposit = (currency, value) => {
  return {
    event: 'confirm_deposit',
    location: 'Deposit review transaction modal',
    description: 'Confirm',
    category: 'Deposit',
    action: 'Confirm Deposit',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 48 - Exit Deposit Confirmation
export const gtmEvent_exit_deposit_confirmation = (currency, value) => {
  return {
    event: 'exit_deposit_confirmation',
    location: 'Deposit review transaction modal',
    description: 'X',
    category: 'Deposit',
    action: 'Exit Deposit Confirmation',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 49 - Confirm Deposit Completion
export const gtmEvent_confirm_deposit_completion = (currency, value) => {
  return {
    event: 'confirm_deposit_completion',
    location: 'Deposit transaction completed modal',
    description: 'Confirm',
    category: 'Deposit',
    action: 'Confirm Deposit Completion',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 50 - Exit Deposit Completion
export const gtmEvent_exit_deposit_completion = (currency, value) => {
  return {
    event: 'exit_deposit_completion',
    location: 'Deposit transaction completed modal',
    description: 'X',
    category: 'Deposit',
    action: 'Exit Deposit Completion',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 51 - Enter Amount
export const gtmEvent_enter_amount = (currency, value) => {
  return {
    event: 'enter_amount',
    location: 'Withdraw page',
    description: '0,00',
    category: 'Withdraw',
    action: 'Enter Amount',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 52 - Confirm Withdraw
export const gtmEvent_confirm_withdraw = (currency, value) => {
  return {
    event: 'confirm_withdraw',
    location: 'Withdraw page',
    description: 'CONFIRM WITHDRAW',
    category: 'Withdraw',
    action: 'Confirm Withdraw',
    label: `{currency: "${currency}", value: "${value}"}`,
  };
};

// Event 53 - Logout
export const gtmEvent_logout = () => {
  return {
    event: 'logout',
    location: 'Top pane',
    description: 'Out arrow',
    category: 'Home',
    action: 'Logout',
  };
};

// Event 54 - Discord
export const gtmEvent_discord = () => {
  return {
    event: 'discord',
    location: 'Top pane',
    description: 'join us on DISCORD',
    category: 'Home',
    action: 'Discord',
  };
};

// Event 55 - Contact email address
export const gtmEvent_contact_email_address = () => {
  return {
    event: 'contact_email_address',
    location: 'Bottom pane',
    description: 'agent@unicornparty.com',
    category: 'Home',
    action: 'Contact email address',
  };
};

// Event 56 - Cancel - Disconnect Wallet - X
export const gtmEvent_cancel_disconnect_wallet_x = () => {
  return {
    event: 'cancel_disconnect_wallet_x',
    location: 'Sign Out / Disconnect Wallet',
    description: 'X',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Disconnect Wallet - X',
  };
};

// Event 57 - Cancel - Sign Out - X
export const gtmEvent_cancel_sign_out_x = () => {
  return {
    event: 'cancel_sign_out_x',
    location: 'Sign Out / Disconnect Wallet',
    description: 'X',
    category: 'Sign Out / Disconnect Wallet',
    action: 'Cancel - Sign Out - X',
  };
};

// Event 58 - X - Unsuccessful Connection
export const gtmEvent_x_unsuccessful_connection = () => {
  return {
    event: 'x_unsuccessful_connection',
    location: 'Login modal',
    description: 'X',
    category: 'Login',
    action: 'X - Unsuccessful Connection',
  };
};

// Event 59 - Mobile - Redirect Deposit Page
export const gtmEvent_mobile_redirect_deposit_page = () => {
  return {
    event: 'mobile_redirect_deposit_page',
    location: 'Home page',
    description: 'Deposit',
    category: 'Home',
    action: 'Mobile - Redirect Deposit Page',
  };
};

// Event 60 - Mobile - Redirect Withdraw Page
export const gtmEvent_mobile_redirect_withdraw_page = () => {
  return {
    event: 'mobile_redirect_withdraw_page',
    location: 'Home page',
    description: 'Withdraw',
    category: 'Home',
    action: 'Mobile - Redirect Withdraw Page',
  };
};

// Event 61 - Mobile - Redirect FAQ Page
export const gtmEvent_mobile_redirect_faq_page = () => {
  return {
    event: 'mobile_redirect_faq_page',
    location: 'Home page',
    description: 'FAQ',
    category: 'Home',
    action: 'Mobile - Redirect FAQ Page',
  };
};

// Event 62 - Mobile - Redirect Privacy Policy Page
export const gtmEvent_mobile_redirect_privacy_policy_page = () => {
  return {
    event: 'mobile_redirect_privacy_policy_page',
    location: 'Home page',
    description: 'Privacy Policy',
    category: 'Home',
    action: 'Mobile - Redirect Privacy Policy Page',
  };
};

// Event 63 - Mobile - Redirect Terms of Service Page
export const gtmEvent_mobile_redirect_terms_of_service_page = () => {
  return {
    event: 'mobile_redirect_terms_of_service_page',
    location: 'Home page',
    description: 'Terms of Service',
    category: 'Home',
    action: 'Mobile - Redirect Terms of Service Page',
  };
};

// #64 Play Now on Home page for Unicorn Trivia
export const mobile_play_now_unicorn_trivia_home = () => {
  return {
    event: 'play_now_unicorn_trivia_home',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now - Unicorn Trivia',
  };
};

// #65 Download APK on Home page for Unicorn Trivia
export const mobile_download_apk_unicorn_trivia_home = () => {
  return {
    event: 'download_apk_unicorn_trivia_home',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK - Unicorn Trivia',
  };
};

// #66 Google Play on Home page for Unicorn Trivia
export const mobile_google_play_unicorn_trivia_home = () => {
  return {
    event: 'google_play_unicorn_trivia_home',
    location: 'Home page',
    description: 'GET IT ON Google Play',
    category: 'Home',
    action: 'Go to Play Store - Unicorn Trivia',
  };
};

// #67 App Store on Home page for Unicorn Trivia
export const mobile_app_store_unicorn_trivia_home = () => {
  return {
    event: 'app_store_unicorn_trivia_home',
    location: 'Home page',
    description: 'Download on the App Store',
    category: 'Home',
    action: 'Go to App Store - Unicorn Trivia',
  };
};

// #68 Play Now on Home page for Bingo UP
export const mobile_play_now_bingo_up_home = () => {
  return {
    event: 'play_now_bingo_up_home',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now - Bingo UP',
  };
};

// #69 Download APK on Home page for Bingo UP
export const mobile_download_apk_bingo_up_home = () => {
  return {
    event: 'download_apk_bingo_up_home',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK - Bingo UP',
  };
};

// #70 Google Play on Home page for Bingo UP
export const mobile_google_play_bingo_up_home = () => {
  return {
    event: 'google_play_bingo_up_home',
    location: 'Home page',
    description: 'GET IT ON Google Play',
    category: 'Home',
    action: 'Go to Play Store - Bingo UP',
  };
};

// #71 App Store on Home page for Bingo UP
export const mobile_app_store_bingo_up_home = () => {
  return {
    event: 'app_store_bingo_up_home',
    location: 'Home page',
    description: 'Download on the App Store',
    category: 'Home',
    action: 'Go to App Store - Bingo UP',
  };
};

// #72 Play Now on Deposit page for Unicorn Trivia
export const mobile_play_now_unicorn_trivia_deposit = () => {
  return {
    event: 'play_now_unicorn_trivia_deposit',
    location: 'Deposit page',
    description: 'PLAY NOW',
    category: 'Deposit',
    action: 'Play Now - Unicorn Trivia',
  };
};

// #73 Download APK on Deposit page for Unicorn Trivia
export const mobile_download_apk_unicorn_trivia_deposit = () => {
  return {
    event: 'download_apk_unicorn_trivia_deposit',
    location: 'Deposit page',
    description: 'DOWNLOAD APK',
    category: 'Deposit',
    action: 'Download APK - Unicorn Trivia',
  };
};

// #74 Google Play on Deposit page for Unicorn Trivia
export const mobile_google_play_unicorn_trivia_deposit = () => {
  return {
    event: 'google_play_unicorn_trivia_deposit',
    location: 'Deposit page',
    description: 'GET IT ON Google Play',
    category: 'Deposit',
    action: 'Go to Play Store - Unicorn Trivia',
  };
};

// #75 App Store on Deposit page for Unicorn Trivia
export const mobile_app_store_unicorn_trivia_deposit = () => {
  return {
    event: 'app_store_unicorn_trivia_deposit',
    location: 'Deposit page',
    description: 'Download on the App Store',
    category: 'Deposit',
    action: 'Go to App Store - Unicorn Trivia',
  };
};

// #76 Play Now on Deposit page for Bingo UP
export const mobile_play_now_bingo_up_deposit = () => {
  return {
    event: 'play_now_bingo_up_deposit',
    location: 'Deposit page',
    description: 'PLAY NOW',
    category: 'Deposit',
    action: 'Play Now - Bingo UP',
  };
};

// #77 Download APK on Deposit page for Bingo UP
export const mobile_download_apk_bingo_up_deposit = () => {
  return {
    event: 'download_apk_bingo_up_deposit',
    location: 'Deposit page',
    description: 'DOWNLOAD APK',
    category: 'Deposit',
    action: 'Download APK - Bingo UP',
  };
};

// #78 Google Play on Deposit page for Bingo UP
export const mobile_google_play_bingo_up_deposit = () => {
  return {
    event: 'google_play_bingo_up_deposit',
    location: 'Deposit page',
    description: 'GET IT ON Google Play',
    category: 'Deposit',
    action: 'Go to Play Store - Bingo UP',
  };
};

// #79 App Store on Deposit page for Bingo UP
export const mobile_app_store_bingo_up_deposit = () => {
  return {
    event: 'app_store_bingo_up_deposit',
    location: 'Deposit page',
    description: 'Download on the App Store',
    category: 'Deposit',
    action: 'Go to App Store - Bingo UP',
  };
};

// #80 Play Now on Withdraw page for Unicorn Trivia
export const mobile_play_now_unicorn_trivia_withdraw = () => {
  return {
    event: 'play_now_unicorn_trivia_withdraw',
    location: 'Withdraw page',
    description: 'PLAY NOW',
    category: 'Withdraw',
    action: 'Play Now - Unicorn Trivia',
  };
};

// #81 Download APK on Withdraw page for Unicorn Trivia
export const mobile_download_apk_unicorn_trivia_withdraw = () => {
  return {
    event: 'download_apk_unicorn_trivia_withdraw',
    location: 'Withdraw page',
    description: 'DOWNLOAD APK',
    category: 'Withdraw',
    action: 'Download APK - Unicorn Trivia',
  };
};

// #82 Google Play on Withdraw page for Unicorn Trivia
export const mobile_google_play_unicorn_trivia_withdraw = () => {
  return {
    event: 'google_play_unicorn_trivia_withdraw',
    location: 'Withdraw page',
    description: 'GET IT ON Google Play',
    category: 'Withdraw',
    action: 'Go to Play Store - Unicorn Trivia',
  };
};

// #83 App Store on Withdraw page for Unicorn Trivia
export const mobile_app_store_unicorn_trivia_withdraw = () => {
  return {
    event: 'app_store_unicorn_trivia_withdraw',
    location: 'Withdraw page',
    description: 'Download on the App Store',
    category: 'Withdraw',
    action: 'Go to App Store - Unicorn Trivia',
  };
};

// #84 Play Now on Withdraw page for Bingo UP
export const mobile_play_now_bingo_up_withdraw = () => {
  return {
    event: 'play_now_bingo_up_withdraw',
    location: 'Withdraw page',
    description: 'PLAY NOW',
    category: 'Withdraw',
    action: 'Play Now - Bingo UP',
  };
};

// #85 Download APK on Withdraw page for Bingo UP
export const mobile_download_apk_bingo_up_withdraw = () => {
  return {
    event: 'download_apk_bingo_up_withdraw',
    location: 'Withdraw page',
    description: 'DOWNLOAD APK',
    category: 'Withdraw',
    action: 'Download APK - Bingo UP',
  };
};

// #86 Google Play on Withdraw page for Bingo UP
export const mobile_google_play_bingo_up_withdraw = () => {
  return {
    event: 'google_play_bingo_up_withdraw',
    location: 'Withdraw page',
    description: 'GET IT ON Google Play',
    category: 'Withdraw',
    action: 'Go to Play Store - Bingo UP',
  };
};

// #87 App Store on Withdraw page for Bingo UP
export const mobile_app_store_bingo_up_withdraw = () => {
  return {
    event: 'app_store_bingo_up_withdraw',
    location: 'Withdraw page',
    description: 'Download on the App Store',
    category: 'Withdraw',
    action: 'Go to App Store - Bingo UP',
  };
};

// #88 Deposit Unicorn Trivia on Deposit page
export const mobile_deposit_unicorn_trivia = () => {
  return {
    event: 'deposit_unicorn_trivia',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Unicorn Trivia',
  };
};

// #89 Deposit Bingo UP on Deposit page
export const mobile_deposit_bingo_up = () => {
  return {
    event: 'deposit_bingo_up',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit',
    action: 'Deposit Bingo UP',
  };
};

// #90 Deposit Package 1 for Unicorn Trivia on Deposit page
export const mobile_deposit_package_1_unicorn_trivia = ({ currency, value }) => {
  return {
    event: 'deposit_package_1_unicorn_trivia',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Unicorn Trivia',
    action: 'Deposit Package 1',
    details: { currency, value },
  };
};

// #91 Deposit Package 2 for Unicorn Trivia on Deposit page
export const mobile_deposit_package_2_unicorn_trivia = ({ currency, value }) => {
  return {
    event: 'deposit_package_2_unicorn_trivia',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Unicorn Trivia',
    action: 'Deposit Package 2',
    details: { currency, value },
  };
};

// #92 Deposit Package 3 for Unicorn Trivia on Deposit page
export const mobile_deposit_package_3_unicorn_trivia = ({ currency, value }) => {
  return {
    event: 'deposit_package_3_unicorn_trivia',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Unicorn Trivia',
    action: 'Deposit Package 3',
    details: { currency, value },
  };
};

// #93 Deposit Package 4 for Unicorn Trivia on Deposit page
export const mobile_deposit_package_4_unicorn_trivia = ({ currency, value }) => {
  return {
    event: 'deposit_package_4_unicorn_trivia',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Unicorn Trivia',
    action: 'Deposit Package 4',
    details: { currency, value },
  };
};

// #94 Deposit Package 1 for Bingo UP on Deposit page
export const mobile_deposit_package_1_bingo_up = ({ currency, value }) => {
  return {
    event: 'deposit_package_1_bingo_up',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bingo UP',
    action: 'Deposit Package 1',
    details: { currency, value },
  };
};

// #95 Deposit Package 2 for Bingo UP on Deposit page
export const mobile_deposit_package_2_bingo_up = ({ currency, value }) => {
  return {
    event: 'deposit_package_2_bingo_up',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bingo UP',
    action: 'Deposit Package 2',
    details: { currency, value },
  };
};

// #96 Deposit Package 3 for Bingo UP on Deposit page
export const mobile_deposit_package_3_bingo_up = ({ currency, value }) => {
  return {
    event: 'deposit_package_3_bingo_up',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bingo UP',
    action: 'Deposit Package 3',
    details: { currency, value },
  };
};

// #97 Deposit Package 4 for Bingo UP on Deposit page
export const mobile_deposit_package_4_bingo_up = ({ currency, value }) => {
  return {
    event: 'deposit_package_4_bingo_up',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bingo UP',
    action: 'Deposit Package 4',
    details: { currency, value },
  };
};

// #98 Withdraw Unicorn Trivia
export const mobile_withdraw_unicorn_trivia = () => {
  return {
    event: 'withdraw_unicorn_trivia',
    location: 'Withdraw page',
    description: 'WITHDRAW',
    category: 'Withdraw',
    action: 'Withdraw Unicorn Trivia',
  };
};

// #99 Withdraw Bingo UP
export const mobile_withdraw_bingo_up = () => {
  return {
    event: 'withdraw_bingo_up',
    location: 'Withdraw page',
    description: 'WITHDRAW',
    category: 'Withdraw',
    action: 'Withdraw Bingo UP',
  };
};

// #100 Enter PayPal for Unicorn Trivia
export const mobile_enter_paypal_unicorn_trivia = () => {
  return {
    event: 'enter_paypal_unicorn_trivia',
    location: 'Withdraw page',
    description: 'Enter PayPal details',
    category: 'Withdraw - Unicorn Trivia',
    action: 'Enter PayPal',
    details: { currency: 'specified_currency', value: 'specified_value' },
  };
};

// #101 Enter PayPal for Bingo UP
export const mobile_enter_paypal_bingo_up = () => {
  return {
    event: 'enter_paypal_bingo_up',
    location: 'Withdraw page',
    description: 'Enter PayPal details',
    category: 'Withdraw - Bingo UP',
    action: 'Enter PayPal',
    details: { currency: 'specified_currency', value: 'specified_value' },
  };
};

// #101 Enter PayPal for Bumper Corns
export const mobile_enter_paypal_bumper_corns = () => {
  return {
    event: 'enter_paypal_bumper_corns',
    location: 'Withdraw page',
    description: 'Enter PayPal details',
    category: 'Withdraw - Bumper Corns',
    action: 'Enter PayPal',
    details: { currency: 'specified_currency', value: 'specified_value' },
  };
};

// #102 Play Now on Home page for Bumper Corns
export const mobile_play_now_bumper_corns_home = () => {
  return {
    event: 'play_now_bumper_corns_home',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now - Bumper Corns',
  };
};

// #103 Download APK on Home page for Bumper Corns
export const mobile_download_apk_bumper_corns_home = () => {
  return {
    event: 'download_apk_bumper_corns_home',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK - Bumper Corns',
  };
};

// #104 Google Play on Home page for Bumper Corns
export const mobile_google_play_bumper_corns_home = () => {
  return {
    event: 'google_play_bumper_corns_home',
    location: 'Home page',
    description: 'GET IT ON Google Play',
    category: 'Home',
    action: 'Go to Play Store - Bumper Corns',
  };
};

// #105 App Store on Home page for Bumper Corns
export const mobile_app_store_bumper_corns_home = () => {
  return {
    event: 'app_store_bumper_corns_home',
    location: 'Home page',
    description: 'Download on the App Store',
    category: 'Home',
    action: 'Go to App Store - Bumper Corns',
  };
};

// #106 Play Now on Deposit page for Bumper Corns
export const mobile_play_now_bumper_corns_deposit = () => {
  return {
    event: 'play_now_bumper_corns_deposit',
    location: 'Deposit page',
    description: 'PLAY NOW',
    category: 'Deposit',
    action: 'Play Now - Bumper Corns',
  };
};

// #107 Download APK on Deposit page for Bumper Corns
export const mobile_download_apk_bumper_corns_deposit = () => {
  return {
    event: 'download_apk_bumper_corns_deposit',
    location: 'Deposit page',
    description: 'DOWNLOAD APK',
    category: 'Deposit',
    action: 'Download APK - Bumper Corns',
  };
};

// #108 Google Play on Deposit page for Bumper Corns
export const mobile_google_play_bumper_corns_deposit = () => {
  return {
    event: 'google_play_bumper_corns_deposit',
    location: 'Deposit page',
    description: 'GET IT ON Google Play',
    category: 'Deposit',
    action: 'Go to Play Store - Bumper Corns',
  };
};

// #109 App Store on Deposit page for Bumper Corns
export const mobile_app_store_bumper_corns_deposit = () => {
  return {
    event: 'app_store_bumper_corns_deposit',
    location: 'Deposit page',
    description: 'Download on the App Store',
    category: 'Deposit',
    action: 'Go to App Store - Bumper Corns',
  };
};

// #110 Play Now on Withdraw page for Bumper Corns
export const mobile_app_store_bumper_corns_withdraw = () => {
  return {
    event: 'app_store_bumper_corns_withdraw',
    location: 'Withdraw page',
    description: 'Download on the App Store',
    category: 'Withdraw',
    action: 'Go to App Store - Bumper Corns',
  };
};

// #111 Deposit Package 1 for Bumper Corns on Deposit page
export const mobile_deposit_package_bumper_corns = ({ currency, value }) => {
  return {
    event: 'deposit_package_1_bumper_corns',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bumper Corns',
    action: 'Deposit Package 1',
    details: { currency, value },
  };
};

// #112 Deposit Package 2 for Bumper Corns on Deposit page
export const mobile_deposit_package_2_bumper_corns = ({ currency, value }) => {
  return {
    event: 'deposit_package_2_bumper_corns',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bingo UP',
    action: 'Deposit Package 2',
    details: { currency, value },
  };
};

// #113 Deposit Package 3 for Bumper Corns on Deposit page
export const mobile_deposit_package_3_bumper_corns = ({ currency, value }) => {
  return {
    event: 'deposit_package_3_bumper_corns',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bumper Corns',
    action: 'Deposit Package 3',
    details: { currency, value },
  };
};

// #114 Deposit Package 4 for Bumper Corns on Deposit page
export const mobile_deposit_package_4_bumper_corns = ({ currency, value }) => {
  return {
    event: 'deposit_package_4_bumper_corns',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bumper Corns',
    action: 'Deposit Package 4',
    details: { currency, value },
  };
};

// #115 Deposit Package 1 for Bumper Corns on Deposit page
export const mobile_deposit_package_1_bumper_corns = ({ currency, value }) => {
  return {
    event: 'deposit_package_1_bumper_corns',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Bumper Corns',
    action: 'Deposit Package 1',
    details: { currency, value },
  };
};

// #116 Download Bingo Landing
export const download_apk_bingo_up_landing = () => {
  return {
    event: 'download_apk_bingo_up_landing',
    location: '/landing',
    description: 'DOWNLOAD',
    category: 'Landing Page',
    action: 'Download Bingo Landing Page',
  };
};

// #117 Download Bingo Landing page
export const download_apk_bingo_up_landing_page = () => {
  return {
    event: 'download_apk_bingo_up_landing_page',
    location: '/landingpage',
    description: 'DOWNLOAD',
    category: 'Landing Page',
    action: 'Download Bingo Landing Page',
  };
};

// #118 Enter PayPal for Mob Run
export const mobile_enter_paypal_mob_run = () => {
  return {
    event: 'enter_paypal_mob_run',
    location: 'Withdraw page',
    description: 'Enter PayPal details',
    category: 'Withdraw - Mob Run',
    action: 'Enter PayPal',
    details: { currency: 'specified_currency', value: 'specified_value' },
  };
};

// #119 Play Now on Home page for Mob Run
export const mobile_play_now_mob_run_home = () => {
  return {
    event: 'play_now_mob_run_home',
    location: 'Home page',
    description: 'PLAY NOW',
    category: 'Home',
    action: 'Play Now - Mob Run',
  };
};

// #120 Download APK on Home page for Mob Run
export const mobile_download_apk_mob_run_home = () => {
  return {
    event: 'download_apk_mob_run_home',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK - Mob Run',
  };
};

// #121 Google Play on Home page for Mob Run
export const mobile_google_play_mob_run_home = () => {
  return {
    event: 'google_play_mob_run_home',
    location: 'Home page',
    description: 'GET IT ON Google Play',
    category: 'Home',
    action: 'Go to Play Store - Mob Run',
  };
};

// #122 App Store on Home page for Mob Run
export const mobile_app_store_mob_run_home = () => {
  return {
    event: 'app_store_mob_run_home',
    location: 'Home page',
    description: 'Download on the App Store',
    category: 'Home',
    action: 'Go to App Store - Mob Run',
  };
};

// #123 Play Now on Deposit page for Mob Run
export const mobile_play_now_mob_run_deposit = () => {
  return {
    event: 'play_now_mob_run_deposit',
    location: 'Deposit page',
    description: 'PLAY NOW',
    category: 'Deposit',
    action: 'Play Now - Mob Run',
  };
};

// #124 Download APK on Deposit page for Mob Run
export const mobile_download_apk_mob_run_deposit = () => {
  return {
    event: 'download_apk_mob_run_deposit',
    location: 'Deposit page',
    description: 'DOWNLOAD APK',
    category: 'Deposit',
    action: 'Download APK - Mob Run',
  };
};

// #125 Google Play on Deposit page for Mob Run
export const mobile_google_play_mob_run_deposit = () => {
  return {
    event: 'google_play_mob_run_deposit',
    location: 'Deposit page',
    description: 'GET IT ON Google Play',
    category: 'Deposit',
    action: 'Go to Play Store - Mob Run',
  };
};

// #126 App Store on Deposit page for Mob Run
export const mobile_app_store_mob_run_deposit = () => {
  return {
    event: 'app_store_mob_run_deposit',
    location: 'Deposit page',
    description: 'Download on the App Store',
    category: 'Deposit',
    action: 'Go to App Store - Mob Run',
  };
};

// #127 Play Now on Withdraw page for Mob Run
export const mobile_play_now_mob_run_withdraw = () => {
  return {
    event: 'play_now_mob_run_withdraw',
    location: 'Withdraw page',
    description: 'Play Now',
    category: 'Withdraw',
    action: 'Play Now - Mob Run',
  };
};

// #128 Deposit Package 1 for Mob Run on Deposit page
export const mobile_deposit_package_1_mob_run = ({ currency, value }) => {
  return {
    event: 'deposit_package_1_mob_run',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Mob Run',
    action: 'Deposit Package 1',
    details: { currency, value },
  };
};

// #129 Deposit Package 2 for Mob Run on Deposit page
export const mobile_deposit_package_2_mob_run = ({ currency, value }) => {
  return {
    event: 'deposit_package_2_mob_run',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Mob Run',
    action: 'Deposit Package 2',
    details: { currency, value },
  };
};

// #130 Deposit Package 3 for Mob Run on Deposit page
export const mobile_deposit_package_3_mob_run = ({ currency, value }) => {
  return {
    event: 'deposit_package_3_mob_run',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Mob Run',
    action: 'Deposit Package 3',
    details: { currency, value },
  };
};

// #131 Deposit Package 4 for Mob Run on Deposit page
export const mobile_deposit_package_4_mob_run = ({ currency, value }) => {
  return {
    event: 'deposit_package_4_mob_run',
    location: 'Deposit page',
    description: 'DEPOSIT',
    category: 'Deposit - Mob Run',
    action: 'Deposit Package 4',
    details: { currency, value },
  };
};

// #132 Select withdraw Mob Run
export const select_withdraw_mob_run = () => {
  return {
    event: 'select_withdraw_mob_run',
    location: 'Withdraw page',
    description: 'Withdraw',
    category: 'Withdraw',
    action: 'Withdraw Mob Run',
  };
};

// #133 Select withdraw Unicorn Trivia
export const select_withdraw_unicorn_trivia = () => {
  return {
    event: 'select_withdraw_unicorn_trivia',
    location: 'Withdraw page',
    description: 'Withdraw',
    category: 'Withdraw',
    action: 'Withdraw Unicorn Trivia',
  };
};

// #134 Select withdraw Bingo UP
export const select_withdraw_bingo_up = () => {
  return {
    event: 'select_withdraw_bingo_up',
    location: 'Withdraw page',
    description: 'Withdraw',
    category: 'Withdraw',
    action: 'Withdraw Bingo UP',
  };
};

// #135 Select withdraw Bumper Corns
export const select_withdraw_bumper_corns = () => {
  return {
    event: 'select_withdraw_bumper_corns',
    location: 'Withdraw page',
    description: 'Withdraw',
    category: 'Withdraw',
    action: 'Withdraw Bumper Corns',
  };
};

// #136 Select Deposit Unicorn Trivia
export const select_deposit_unicorn_trivia = () => {
  return {
    event: 'select_deposit_unicorn_trivia',
    location: 'Deposit page',
    description: 'Deposit',
    category: 'Deposit',
    action: 'Deposit Unicorn Trivia',
  };
};

// #137 Select Deposit Bingo UP
export const select_deposit_bingo_up = () => {
  return {
    event: 'select_deposit_bingo_up',
    location: 'Deposit page',
    description: 'Deposit',
    category: 'Deposit',
    action: 'Deposit Bingo UP',
  };
};

// #138 Select Deposit Bumper Corns
export const select_deposit_bumper_corns = () => {
  return {
    event: 'select_deposit_bumper_corns',
    location: 'Deposit page',
    description: 'Deposit',
    category: 'Deposit',
    action: 'Deposit Bumper Corns',
  };
};

// #139 Select Deposit Mob Run
export const select_deposit_mob_run = () => {
  return {
    event: 'select_deposit_mob_run',
    location: 'Deposit page',
    description: 'Deposit',
    category: 'Deposit',
    action: 'Deposit Mob Run',
  };
};

// #140 Type email address withdraw paypal
export const type_email_address_withdraw_paypal = () => {
  return {
    event: 'type_email_address_withdraw_paypal',
    location: 'Withdraw page',
    description: 'Type email address',
    category: 'Withdraw',
    action: 'Type email address - Withdraw PayPal',
  };
};

// #141 - Transactions
export const gtmEvent_go_to_transactions = () => {
  return {
    event: 'go_to_transactions',
    location: 'Login page top pane',
    description: 'Transactions',
    category: 'Login',
    action: 'Go to Transactions',
  };
};

// #142 - FAQ
export const gtmEvent_go_to_faq = () => {
  return {
    event: 'go_to_faq',
    location: 'Login page top pane',
    description: 'FAQ',
    category: 'Login',
    action: 'Go to FAQ',
  };
};

// #143 - LOGIN
export const gtmEvent_go_to_login = () => {
  return {
    event: 'go_to_login',
    location: 'Login page',
    description: 'LOGIN',
    category: 'Login',
    action: 'Login',
  };
};

// #144 Download APK on Home page for Solitaire UP
export const mobile_download_apk_solitaire_up_home = () => {
  return {
    event: 'download_apk_solitaire_up_home',
    location: 'Home page',
    description: 'DOWNLOAD APK',
    category: 'Home',
    action: 'Download APK - Solitaire UP',
  };
};

// #145 Google Play on Home page for Solitaire UP
export const mobile_google_play_solitaire_up_home = () => {
  return {
    event: 'google_play_solitaire_up_home',
    location: 'Home page',
    description: 'GET IT ON Google Play',
    category: 'Home',
    action: 'Go to Play Store - Solitaire UP',
  };
};

// #146 App Store on Home page for Solitaire UP
export const mobile_app_store_solitaire_up_home = () => {
  return {
    event: 'app_store_solitaire_up_home',
    location: 'Home page',
    description: 'Download on the App Store',
    category: 'Home',
    action: 'Go to App Store - Solitaire UP',
  };
};
